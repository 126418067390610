import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import featureCollection from './camera.json'
import PullToRefresh from 'react-simple-pull-to-refresh';
import { useWakeLock } from 'react-screen-wake-lock';
import AddToHomescreen from 'react-add-to-homescreen';

function App() {
  const distanceThreshold=0.500;

  const [nearbyCameras,setNearbyCameras]=useState<Array<any>>([])
  const [loading,setLoading]=useState<boolean>(false)
  const [nextCam,setNextCam]=useState<any>()
  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => {},
    onError: () => {},
    onRelease: () => {},
  });

  function haversineDistance(coords1:Array<number>, coords2:Array<number>, isMiles:boolean) {
    function toRad(x:number) {
      return x * Math.PI / 180;
    }
  
    var lon1 = coords1[0];
    var lat1 = coords1[1];
  
    var lon2 = coords2[0];
    var lat2 = coords2[1];
  
    var R = 6371; // km
  
    var x1 = lat2 - lat1;
    var dLat = toRad(x1);
    var x2 = lon2 - lon1;
    var dLon = toRad(x2)
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
  
    if(isMiles) d /= 1.60934;
  
    return d;
  }

  function play(camid) {
//     var x = document.createElement("AUDIO");

// x.setAttribute("src",  "/alarm.wav");
// x.setAttribute("autoplay", "");
// x.setAttribute("muted", "false");

// document.body.appendChild(x);

    (document.getElementById('notification') as any).muted = false;

    (document.getElementById('notification') as any).play().then(c=>{
      localStorage.setItem("nextCam",camid);
    }).catch(e=>console.log(e));
  }

  function onLocationSuccess(position:any) {
    console.log("Latitude is :", position.coords.latitude);
    console.log("Longitude is :", position.coords.longitude);
    let nms:Array<any> = (featureCollection.features as Array<any>).reduce((acc,s)=>{
        const distance=+haversineDistance(s.geometry.coordinates,[position.coords.longitude,position.coords.latitude],false).toFixed(2);
        if(distance<20){
          acc.push({...s,distance:distance})
        }
        return acc;
    },[])
    nms.sort((a,b)=>a.distance-b.distance);


    if(nms.length>0){
      const xtList=new Array()
      nms.forEach(currentNear => {
        let lastCoords = localStorage.getItem("lastCoords")
        if(lastCoords){
          const [llat,llon] = lastCoords.split(":")
          const lastDistance=+haversineDistance(currentNear.geometry.coordinates,[+llon,+llat],false).toFixed(2)
          if(lastDistance>=currentNear.distance){
            xtList.push(currentNear);
          }
        }
        localStorage.setItem("lastCoords",position.coords.latitude+":"+position.coords.longitude);        
      });
      nms=xtList;
    }
    
    if(nms && nms[0] && nms[0].distance<distanceThreshold){

      let alarmed=false;
      let ncSaved=localStorage.getItem("nextCam");
      if(ncSaved==nms[0].properties["Unique ID"]){
        alarmed=true;
      }
      if(!alarmed){
        try{
          play(nms[0].properties["Unique ID"])
        }catch(e){

        }
      }
      
    }
    setNearbyCameras(nms);
    setLoading(false);
  }


  const loadData=()=>{
    if(!loading){
      setLoading(true)
      navigator.geolocation.getCurrentPosition(onLocationSuccess);
    }
    
  }

  const watchPositions=()=>{
    const wid=localStorage.getItem("watchId");
    if(wid){
      navigator.geolocation.clearWatch(+wid);
    }
    const watchId=navigator.geolocation.watchPosition(onLocationSuccess, function(error){
      loadData();
    }, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    });
    localStorage.setItem("watchId",""+watchId);
  }

  const [watchId,setWatchId]=useState<any>()

  useEffect(()=>{
    request()
    if(!watchId){
      loadData();
      const iid = setInterval(loadData,5000)
      setWatchId(iid)
    }
    return ()=>{
      //release()
      watchId && clearInterval(watchId)
    }

  },[])

  async function handleRefresh() {
    loadData();
  }
  const handleAddToHomescreenClick = () => {
    alert(`
      1. Open Share menu
      2. Tap on "Add to Home Screen" button`);
  };
  return (
    <div className="App">
      <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />

      
      <div className="px-2 fixed z-50  max-w-xl m-auto bg-white shadow-xl flex items-center justify-between fixed z-50 w-full h-16  -translate-x-1/2 bg-white border border-gray-200 left-1/2 dark:bg-gray-700 dark:border-gray-600 ">
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">AI Cameras(<span>11.0</span>): Nearby list</h5>
          <a href="#" className="text-black hover:text-white bg-gray-300 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-1 text-center mr-2 mb-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={handleRefresh}>
  {
    loading && <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
    </svg>
  }Refresh
          </a>
        </div>
      
      <div className="relative m-auto w-full max-w-xl p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        
       
        <div className="flow-root">
        <hr className="h-16"/>

        <PullToRefresh onRefresh={handleRefresh} >
          

          <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
           
           {
             nearbyCameras.map((c,i)=>{
               return   <li className={`py-3 sm:py-4 ${c.distance<=distanceThreshold?"blink":""}`} key={i}>
               <div className="flex items-center space-x-4">
                 <div className="flex-shrink-0">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                     {c.distance>1? c.distance.toFixed(2) +" KM" : (c.distance*1000).toFixed(2) +" Metres"}
                   </p>
                 </div>
                 <div className="flex-1 min-w-0">
                   <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                     {c.properties.name}
                   </p>
                   
                   <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                   {c.properties.District}
                   </p>
                 </div>
                 <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                   <a rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${c.geometry.coordinates[1]},${c.geometry.coordinates[0]}`} target="_blank" className=" text-black hover:text-white bg-gray-300 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Map View</a>
                 </div>
               </div>
             </li>
           
             })
           }
          </ul>
          </PullToRefresh>

        </div>
      </div>

      <div className="fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-4 left-1/2 dark:bg-gray-700 dark:border-gray-600">
    <div className="grid h-full max-w-lg grid-cols-1 mx-auto">
      
        <div className="flex items-center justify-center">
            <button onClick={handleRefresh} data-tooltip-target="tooltip-new" type="button" className="inline-flex items-center justify-center w-10 h-10 font-medium bg-blue-600 rounded-full hover:bg-blue-700 group focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
               
                <svg className={`${loading?"animate-spin":""} text-white h-6 w-6`} xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
  </svg>
                <span className="sr-only">Refresh</span>
            </button>
        </div>
        <div id="tooltip-new" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
            Refresh
            <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
        {/* <button data-tooltip-target="tooltip-settings" type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
            <svg className="w-6 h-6 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
            </svg>
            <span className="sr-only">Settings</span>
        </button>
        <div id="tooltip-settings" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
            Settings
            <div className="tooltip-arrow" data-popper-arrow></div>
        </div> */}
        {/* <button data-tooltip-target="tooltip-profile" type="button" className="inline-flex flex-col items-center justify-center px-5 rounded-r-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
            <svg className="w-6 h-6 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clip-rule="evenodd" fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"></path>
            </svg>
            <span className="sr-only">Profile</span>
        </button>
        <div id="tooltip-profile" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
            Profile
            <div className="tooltip-arrow" data-popper-arrow></div>
        </div> */}
    </div>
</div>

    </div>
  );
}

export default App;
